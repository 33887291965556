.App {
  position: absolute;
  overflow: hidden;
  top:0%;
  left:0%;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: Retroica;
  src: url(fonts/Retroica.woff2) format('woff2'),
  url(fonts/Retroica.woff) format('woff');
}
